import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Box,
  Container,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';
import stars from "src/static/stars.png";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    paddingTop: 128,
    paddingBottom: 128
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular
  },
  center: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "4rem"
  }
}));

const Testimonials = ({ className, ...rest }) => {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="md">
        <img src={stars} alt="stars" className={classes.center}></img>
        <Grid
          container
          spacing={3}>
            <Grid

              item
              xs={12}
              md={4}
            >
              <Typography
                variant="h4"
                align="center"
                color="textPrimary"
                className={classes.title}
              >
                {t('home.testimonials.testimonial1.text1')}
                <br />
                {t('home.testimonials.testimonial1.text2')}
              </Typography>
              <Box
                mt={6}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Avatar src="/static/home/atle.jpg" />
                <Box ml={2}>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    Atle Vabø
                    <Typography
                      color="textSecondary"
                      display="inline"
                      component="span"
                    >
                      , {t('home.testimonials.testimonial1.location')}
                    </Typography>
                  </Typography>
                </Box>
              </Box>
          </Grid>
          <Grid

            item
            xs={12}
            md={4}
          >
            <Typography
              variant="h4"
              align="center"
              color="textPrimary"
              className={classes.title}
            >
              {t('home.testimonials.testimonial2.text1')}
                <br />
              {t('home.testimonials.testimonial2.text2')}
              </Typography>
            <Box
              mt={6}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Avatar src="/static/home/sarita.jpg" />
              <Box ml={2}>
                <Typography
                  variant="body1"
                  color="textPrimary"
                >
                  Sarita Solstad
                    <Typography
                    color="textSecondary"
                    display="inline"
                    component="span"
                  >
                    , {t('home.testimonials.testimonial2.location')}
                    </Typography>
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid

            item
            xs={12}
            md={4}
          >
            <Typography
              variant="h4"
              align="center"
              color="textPrimary"
              className={classes.title}
            >
              {t('home.testimonials.testimonial3.text1')}
                <br />
              {t('home.testimonials.testimonial3.text2')}
              </Typography>
            <Box
              mt={6}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Avatar src="/static/home/olivier.png" />
              <Box ml={2}>
                <Typography
                  variant="body1"
                  color="textPrimary"
                >
                  Jørgen Olsen
                    <Typography
                    color="textSecondary"
                    display="inline"
                    component="span"
                  >
                    , {t('home.testimonials.testimonial3.location')}
                    </Typography>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

Testimonials.propTypes = {
  className: PropTypes.string
};

export default Testimonials;
