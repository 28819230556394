/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Tool as ToolIcon,
  Home as HomeIcon,
  PieChart as PieChartIcon,
  MessageSquare as MessageIcon,
  Layers as ContractsIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import {useTranslation} from "react-i18next";

const sections = [
  {
    subheader: 'Reports',
    items: [
      {
        title: 'Dashboard',
        icon: PieChartIcon,
        href: '/app/reports/dashboard'
      }
    ]
  },
  {
    subheader: 'Management',
    items: [
      {
        title: 'Apartments',
        icon: HomeIcon,
        href: '/app/management/apartments',
        items: [
          {
            title: 'List Apartments',
            href: '/app/management/apartments'
          },
        ]
      },
      {
        title: 'Services',
        icon: ToolIcon,
        href: '/app/management/services',
        items: [
          {
            title: 'List Services',
            href: '/app/management/services'
          },
        ]
      },
      {
        title: 'Invoices',
        icon: ReceiptIcon,
        href: '/app/management/invoices',
        items: [
          {
            title: 'List Invoices',
            href: '/app/management/invoices'
          },
        ]
      },
      /*
      {
        title: 'Contracts',
        icon: ContractsIcon,
        href: '/app/management/contracts',
        items: [
          {
            title: 'Contracts Overview',
            href: '/app/management/contracts'
          },
        ]
      },
       */
      {
        title: 'Messages',
        icon: MessageIcon,
        href: '/app/chat',
      }
    ]
  }
];

const norwegianSections = [
  {
    subheader: 'Rapporter',
    items: [
      {
        title: 'Admin Panel',
        icon: PieChartIcon,
        href: '/app/reports/dashboard'
      }
    ]
  },
  {
    subheader: 'Administasjon',
    items: [
      {
        title: 'Utleieenheter',
        icon: HomeIcon,
        href: '/app/management/apartments',
        items: [
          {
            title: 'Utleieenheter',
            href: '/app/management/apartments'
          },
        ]
      },
      {
        title: 'Servicer',
        icon: ToolIcon,
        href: '/app/management/services',
        items: [
          {
            title: 'Servicer',
            href: '/app/management/services'
          },
        ]
      },
      {
        title: 'Fakturaer',
        icon: ReceiptIcon,
        href: '/app/management/invoices',
        items: [
          {
            title: 'Fakturaer',
            href: '/app/management/invoices'
          },
        ]
      },
      /*
      {
        title: 'Contracts',
        icon: ContractsIcon,
        href: '/app/management/contracts',
        items: [
          {
            title: 'Contracts Overview',
            href: '/app/management/contracts'
          },
        ]
      },
       */
      {
        title: 'Meldinger',
        icon: MessageIcon,
        href: '/app/chat',
      }
    ]
  }
];

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  let activeSections = null;

  if (i18n.language === 'en') {
    activeSections = sections
  } else {
    activeSections = norwegianSections
  }

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/app/account">
              <Avatar
                alt="User"
                className={classes.avatar}
                src={user.avatar}
              />
            </RouterLink>
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {t('dashboardLayout.tier')}
              {' '}
              <Link
                component={RouterLink}
                to="/pricing"
              >
                {user.tier}
              </Link>
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {activeSections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
