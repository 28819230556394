import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  Button,
  Box,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingTop: 128,
    paddingBottom: 128
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  font: {
    fontFamily: `'Sen', sans- serif`,
    fontWeight: 700
  }
}));

const Features = ({ className, ...rest }) => {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <div
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Container maxWidth="lg">
        <Typography
          component="p"
          variant="overline"
          color="secondary"
          align="center"
        >
          {t('home.features.title')}
        </Typography>
        <Typography
          variant="h1"
          align="center"
          color="textPrimary"
          className={classes.font}
        >
          {t('home.features.subtitle')}
        </Typography>
        <Box mt={8}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <img 
                src="/static/home/flow.png" 
                alt="flow-icon" 
                width="25px"
                height="30px"
                ></img>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('home.features.feature1.title')}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    {t('home.features.feature1.body')}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <img 
                  src="/static/home/invoices.png" 
                  alt="flow-icon" 
                  width="20px"
                  height="30px"
                ></img>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('home.features.feature2.title')}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                  >
                    {t('home.features.feature2.body')}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
            >
              <Box display="flex">
                <img 
                  src="/static/home/services.png" 
                  alt="flow-icon" 
                  width="30px"
                  height="30px"
                ></img>
                <Box ml={2}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="textPrimary"
                  >
                    {t('home.features.feature3.title')}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    gutterBottom
                  >
                    {t('home.features.feature3.body')}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

Features.propTypes = {
  className: PropTypes.string
};

export default Features;
