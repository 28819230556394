import {createSlice} from "@reduxjs/toolkit";
import {db} from "../utils/firebase";
import {ownerDocument} from "@material-ui/core";
import unit from "./unit";


const initialState = {
  activeListingById: null,
  listings: [],
  listingsByOwnerId: [],
}

const slice = createSlice({
  name: 'listing',
  initialState,
  reducers: {
    getListings(state, action) {
      state.listings = action.payload;
    },
    getListingById(state, action) {
      state.activeListingById = action.payload;
    },
    getListingByOwnerId(state, action) {
      state.listingsByOwnerId = action.payload;
    },
    addListing(state, action) {
      state.listingsByOwnerId.push({
        ...action.payload.newListing,
        owner: action.payload.owner,
      });
      state.listings.push({
        ...action.payload.newListing,
        owner: action.payload.owner,
      })
    },
    updateListing(state, action) {
      state.activeListingById = {
        ...state.activeListingById,
        ...action.payload,
      };
    }
  }
});

export const getListings = () => async (dispatch) => {
  try {
    let listings = [];

    const listingsSnapshot = await db.collection('listings')
      .where('status', '==', 'active')
      .get();

    if (listingsSnapshot.docs.length > 0) {
      for (const doc of listingsSnapshot.docs) {
        const unitDocRef = doc.data().unit;

        if (!unitDocRef) {
          continue;
        }

        //const unitDoc = await unitDocRef.get();

        let newListing = {
          id: doc.id,
          owner: null,
          name: doc.data().name,
          size: doc.data().size,
          description: doc.data().description,
          price: doc.data().price,
          address: doc.data().address,
          city: doc.data().city,
          powerIncluded: doc.data().powerIncluded,
          internetIncluded: doc.data().internetIncluded,
          availableDate: doc.data().availableDate,
          images: doc.data().images,
          type: doc.data().type,
        }

        const listingOwnerDoc = await doc.data().owner.get();

        const ownerData = {
          name: listingOwnerDoc.data().name,
          avatar: listingOwnerDoc.data().imageLink,
          id: listingOwnerDoc.data().id,
          email: listingOwnerDoc.data().email,
        }

        newListing.owner = ownerData;

        // Extra data if the unit is an apartment and not a room
        if (newListing.type !== 'Room') {
          newListing = {
            ...newListing,
            bedrooms: doc.data().bedrooms
          }
        }

        listings.push(newListing);
      }
    }

    dispatch(slice.actions.getListings(listings));
  } catch (e) {
    console.error(e);
  }
};

export const getListing = (listingId) => async (dispatch) => {
  try {
    const doc = await db.collection('listings').doc(listingId).get();

    const unitDocRef = doc.data().unit;

    const unitDoc = await unitDocRef.get();

    const ownerDoc = await doc.data().owner.get();

    const owner = {
      name: ownerDoc.data().name,
      id: ownerDoc.id,
      image: ownerDoc.data().imageLink
    }

    let listingData = {
      unit: unitDoc.data().id,
      owner: owner,
      id: doc.id,
      name: doc.data().name,
      size: doc.data().size,
      description: doc.data().description,
      price: doc.data().price,
      city: doc.data().city,
      address: doc.data().address,
      powerIncluded: doc.data().powerIncluded,
      internetIncluded: doc.data().internetIncluded,
      images: doc.data().images,
      availableDate: doc.data().availableDate,
      type: doc.data().type,
    };

    // Extra data if the unit is an apartment and not a room
    if (listingData.type === 'Apartment-Complex') {
      listingData = {
        ...listingData,
        bedrooms: doc.data().bedrooms
      }
    }

    await dispatch(slice.actions.getListingById(listingData));
  } catch (e) {

  }
};

export const getListingByOwnerId = (ownerId) => async (dispatch) => {
  try {
    const ownerDocRef = db.collection('users').doc(ownerId);

    const listingsData = [];
    const querySnapshot = await db.collection('listings')
      .where('owner', '==', ownerDocRef)
      .get();

    for (const doc of querySnapshot.docs) {

      const imageDoc = await db.doc(doc.data().image).get();

      let newListingData = {
        unit: null,
        owner: null,
        id: doc.id,
        name: doc.data().name,
        size: doc.data().size,
        description: doc.data().description,
        price: doc.data().price,
        city: doc.data().city,
        address: doc.data().address,
        powerIncluded: doc.data().powerIncluded,
        images: imageDoc.data(),
        internetIncluded: doc.data().internetIncluded,
        availableDate: doc.data().availableDate,
        type: doc.data().type,
      };

      // Extra data if the unit is an apartment and not a room
      if (newListingData.type === 'Apartment-Complex') {
        newListingData = {
          ...newListingData,
          bedrooms: doc.data().bedrooms
        }
      }

      if (doc.data().owner) {
        const owner = await doc.data().owner.get();
        newListingData.owner = owner.data();
        const unit = await doc.data().unit.get();
        newListingData.unit = unit.data();
      } else {
        console.error("getListings : Error! No owner info.");
      }

      listingsData.push(newListingData);
    }
    await dispatch(slice.actions.getListingByOwnerId(listingsData));
  } catch (e) {
    console.error(e);
  }
}

export const addListing = (owningUnit, newListing, user) => async (dispatch) => {
  try {
    let owningUnitRef = db.collection('units').doc(owningUnit.id);

    const owningUnitDoc = await owningUnitRef.get();

    const owningApartmentDoc = await owningUnitDoc.data()['owningApartment'].get();

    const ownerRef = owningUnitDoc.data().owner;

    const newListingDocData = {
      ...newListing,
      status: 'active',
      images: owningUnit.images,
      unit: owningUnitRef,
      owner: ownerRef,
      city: owningApartmentDoc.data().city
    };

    const newListingDocRef = await db.collection('listings').doc();
    await newListingDocRef.set(newListingDocData);

    newListing.id = newListingDocRef.id;

    await dispatch(slice.actions.addListing({
      owner: user.id,
      newListing: newListing
    }));

  } catch (e) {
    console.error(e);
  }
}

export const updateListing = (newListing) => async (dispatch) => {
  try {
    const listingDocRef = db.collection('listings').doc(newListing.id);

    const { id, ...updateInfo } = newListing;

    await listingDocRef.update(updateInfo);

    await dispatch(slice.actions.updateListing(newListing));
  } catch (e) {
    console.error(e);
  }
}

export const reducer = slice.reducer;

export default slice;
