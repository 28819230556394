import { createSlice } from '@reduxjs/toolkit';
import { db } from 'src/utils/firebase';

const initialState = {
  apartments: [],              // all apartments with owner info
  apartmentsByOwnerId: [],     // landlord : true, current user's apartments without owner info
  apartmentsByRenterId: [],    // landlord : false, current user's apartments info with owner info
  apartmentsByAdminId: [],     // admin : Lets users added by owner edit and change apartment info
  apartmentById: null,         // apartment got by it's id for apartment edit view or units view, without owner info
};

const slice = createSlice({
  name: 'apartment',
  initialState,
  reducers: {
    getApartments(state, action) {
      state.apartments = action.payload;
    },
    getApartmentById(state, action) {
      state.apartmentById = action.payload;
    },
    getApartmentsByOwnerId(state, action) {
      state.apartmentsByOwnerId = action.payload;
    },
    getApartmentsByRenterId(state, action) {
      state.apartmentsByRenterId = action.payload;
    },
    getApartmentsByAdminId(state, action) {
      state.apartmentsByAdminId = action.payload;
    },
    addApartment(state, action) {
      state.apartmentsByOwnerId.push({
        ...action.payload.newApartment,
        owner: action.payload.owner,
      });
      state.apartments.push({
        ...action.payload.newApartment,
        owner: action.payload.owner,
      });

    },
    deleteApartment(state, action) {

    },
    addAdminToApartment(state, action) {
      state.apartmentsByAdminId.push({
        ...action.payload
      })
    },
    updateApartment(state, action) {
      state.apartmentById = {
        ...state.apartmentById,
        ...action.payload,
      };
    },
  }
});

export const reducer = slice.reducer;

export const getApartments = () => async (dispatch) => {
  try {
    const apartmentsData = [];
    const querySnapshot = await db.collection("apartments").get();
    for(const doc of querySnapshot.docs){
      const newApartmentData = doc.data();
      newApartmentData.id = doc.id;

      if (doc.data().owner) {
        const user = await doc.data().owner.get();
        newApartmentData.owner = user.data();
        apartmentsData.push(newApartmentData);
      } else {
        console.error("getApartments : Error! No owner info.");
      }
    }
    dispatch(slice.actions.getApartments(apartmentsData));
  } catch (err) {
    console.error(err);
  }
};

export const getApartmentsByOwnerId = (ownerId) => async (dispatch) => {
  try {

    const ownerDocRef = db.collection('users').doc(ownerId);

    const apartmentsData = [];
    const querySnapshot = await db.collection('apartments')
    .where('owner', '==', ownerDocRef)
    .get();

    for(const doc of querySnapshot.docs) {

      let newApartmentData = {
        id: doc.id,
        units: doc.data().units,
        address: doc.data().address,
        city: doc.data().city,
        country: doc.data().country,
        zipcode: doc.data().zipcode,
        type: doc.data().type,
        unitType: doc.data().unitType,
        imageLink: doc.data().imageLink || '',
        imageId: doc.data().imageId || '',
        owner: null,
      };

      if (newApartmentData.type === 'Single-Unit') {
        newApartmentData = {
          ...newApartmentData,
          bedrooms: doc.data().bedrooms,
        }
      } else {
        newApartmentData = {
          ...newApartmentData,
          floors: doc.data().floors,
        }
      }

      if (doc.data().owner) {
        const owner = await doc.data().owner.get();
        newApartmentData.owner = owner.data();
      } else {
        console.error("getApartments : Error! No owner info.");
      }

      apartmentsData.push(newApartmentData);
    }
    dispatch(slice.actions.getApartmentsByOwnerId(apartmentsData));
  } catch (err) {
    console.error(err);
  }
};

export const getApartmentsByRenterId = (renterId) => async (dispatch) => {
  try {

    const renterDocRef = db.collection('users').doc(renterId);

    const unitsSnapshot = await db.collection('units')
    .where('renter', '==', renterDocRef)
    .get();

    let apartmentsData = [];
    let apartmentsDocRef= [];
    let apartmentIds = [];
    let unitCnts = {};
    for(const unitDoc of unitsSnapshot.docs) {
      const apartmentDocRef = unitDoc.data()['owningApartment'];
      if (apartmentIds.includes(apartmentDocRef.id)) {
        unitCnts[apartmentDocRef.id]++;
      } else {
        apartmentIds.push(apartmentDocRef.id);
        unitCnts[apartmentDocRef.id] = 1;
        apartmentsDocRef.push(apartmentDocRef);
      }
    }

    for(const apartmentDocRef of apartmentsDocRef) {
      const apartmentDoc = await apartmentDocRef.get();
      let apartmentOwner = {};
      const apartmentOwnerDocRef = apartmentDoc.data().owner;
      if (apartmentOwnerDocRef) {
        const apartmentOwnerDoc = await apartmentOwnerDocRef.get();
        apartmentOwner = {
          id: apartmentOwnerDocRef.id,
          email: apartmentOwnerDoc.data().email,
          name: apartmentOwnerDoc.data().name,
          phone: apartmentOwnerDoc.data().phone,
        }
      }

      let newApartmentData = {
        id: apartmentDocRef.id,
        address: apartmentDoc.data().address,
        city: apartmentDoc.data().city,
        country: apartmentDoc.data().country,
        zipcode: apartmentDoc.data().zipcode,
        owner: apartmentOwner,
        type: apartmentDoc.data().type,
        unitType: apartmentDoc.data().unitType,
        imageLink: apartmentDoc.data().imageLink || '',
        imageId: apartmentDoc.data().imageId || '',
        rentCnt: unitCnts[apartmentDocRef.id],
      }

      if (newApartmentData.type == 'Single-Unit') {
        newApartmentData = {
          ...newApartmentData,
          bedrooms: apartmentDoc.data().bedrooms,
        }
      } else {
        newApartmentData = {
          ...newApartmentData,
          floors: apartmentDoc.data().floors,
        }
      }

      apartmentsData.push(newApartmentData);
    }
    dispatch(slice.actions.getApartmentsByRenterId(apartmentsData));
  } catch (err) {
    console.error(err);
  }
};

export const getApartmentById = (apartmentId) => async (dispatch) => {
  try {
    const doc = await db.collection("apartments").doc(apartmentId).get();
    let apartmentDataById = {
      id: doc.id,
      units: doc.data().units,
      address: doc.data().address,
      city: doc.data().city,
      country: doc.data().country,
      zipcode: doc.data().zipcode,
      type: doc.data().type,
      unitType: doc.data().unitType,
      imageLink: doc.data().imageLink || '',
      imageId: doc.data().imageId || '',
      owner: null,
    };

    if (apartmentDataById.type === 'Single-Unit') {
      apartmentDataById = {
        ...apartmentDataById,
        bedrooms: doc.data().bedrooms,
      }
    } else {
      apartmentDataById = {
        ...apartmentDataById,
        floors: doc.data().floors,
      }
    }

    if (doc.data().owner) {
      const owner = await doc.data().owner.get();
      apartmentDataById.owner = owner.data();
    } else {
      console.error("getApartments : Error! No owner info.");
    }

    dispatch(slice.actions.getApartmentById(apartmentDataById));

  } catch (err) {
    console.error(err);
  }
}

export const addApartment = (owner, newApartment) => async (dispatch) => {
  try{
    const ownerDocRef = db.doc(`users/${owner.id}`);

    let newApartmentDocData = {
      ...newApartment,
      owner: ownerDocRef,
    }

    if (newApartment.type === 'Single-Unit') {
      newApartmentDocData = {
        ...newApartmentDocData,
        imageLink: '',
        imagePath: '',
        bedrooms: newApartment.bedrooms,
      }
    }

    const newApartmentDocRef = await db.collection("apartments").doc();
    await newApartmentDocRef.set(newApartmentDocData);

    newApartment.id = newApartmentDocRef.id;

    dispatch(slice.actions.addApartment({
      owner: owner,
      newApartment: newApartment,
    }));

  } catch(err){
    console.error(err);
  }
};

export const updateApartment = (newApartment) => async (dispatch) => {
  try{
    const apartmentDocRef = db.collection('apartments').doc(newApartment.id);

    const { id, ...updateInfo } = newApartment;

    await apartmentDocRef.update(updateInfo);

    dispatch(slice.actions.updateApartment(newApartment));

  } catch(err) {
    console.error(err);
  }
}

export const addAdminToApartment = (apartment, user) => async (dispatch) => {
  try {
    const apartmentDocRef = db.collection('apartments').doc(apartment.id);
    const userDocRef = db.collection('users').doc(user.id);

    let apartmentDocData = {
      ...apartment
    }

    const adminAccessDoc = db.collection('admins').doc(apartment.id);

    await adminAccessDoc.set({
      apartment: apartmentDocRef,
      key: Math.random(),
      user: userDocRef
    })

    dispatch(slice.actions.addAdminToApartment(adminAccessDoc))
  } catch (e) {
    console.error(e)
  }
}

export const getApartmentsByAdminId = (adminId) => async (dispatch) => {
  try {

    const userDocRef = db.collection('users').doc(adminId);

    const apartmentsData = [];
    const querySnapshot = await db.collection('admins')
      .where('user', '==', userDocRef)
      .get();

    for(const originDoc of querySnapshot.docs) {

      const doc = originDoc.data().apartment.get();

      let newApartmentData = {
        id: doc.id,
        units: doc.data().units,
        address: doc.data().address,
        city: doc.data().city,
        country: doc.data().country,
        zipcode: doc.data().zipcode,
        type: doc.data().type,
        unitType: doc.data().unitType,
        imageLink: doc.data().imageLink || '',
        imageId: doc.data().imageId || '',
        owner: null,
      };

      if (newApartmentData.type === 'Single-Unit') {
        newApartmentData = {
          ...newApartmentData,
          bedrooms: doc.data().bedrooms,
        }
      } else {
        newApartmentData = {
          ...newApartmentData,
          floors: doc.data().floors,
        }
      }

      if (doc.data().owner) {
        const owner = await doc.data().owner.get();
        newApartmentData.owner = owner.data();
      } else {
        console.error("getApartments : Error! No owner info.");
      }

      apartmentsData.push(newApartmentData);
    }
    dispatch(slice.actions.getApartmentsByOwnerId(apartmentsData));
  } catch (err) {
    console.error(err);
  }
}


export default slice;
