import { createSlice } from '@reduxjs/toolkit';
import { db } from 'src/utils/firebase';

const initialState = {
  contracts: [],
  templateById: null,
  contractAttachedToUnit: null,
  contractTemplates: [],
  contractsSentByUser: [],
  contractsReceivedByUser: [],
  contractTemplatesByUser: [],
  standardContractTemplates: [],
}

const slice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    getContracts(state, action) {
      state.contracts = action.payload;
    },
    getTemplateById(state, action) {
      state.templateById = action.payload;
    },
    getContractByUnit(state, action) {
      state.contractAttachedToUnit = action.payload;
    },
    getContractTemplates(state, action) {
      state.contractTemplates = action.payload;
    },
    getContractsSentByUser(state, action) {
      state.contractsSentByUser = action.payload;
    },
    sendNewContractToRenter(state, action) {
      state.contracts = action.payload;
    },
    getContractsReceivedByUser(state, action) {
      state.contractsReceivedByUser = action.payload;
    },
    getContractTemplatesByUser(state, action) {
      state.contractTemplatesByUser = action.payload;
    },
    updateTemplate(state, action) {
      state.templateById = {
        ...state.templateById,
        ...action.templateById,
      };
    },
  }
});

export const reducer = slice.reducer;

export const getContracts = () => async (dispatch) => {
  try {
    const contractsData = [];
    const querySnapshot = await db.collection('contracts').get();
    for (const doc of querySnapshot.docs) {
      const newContractData = doc.data();
      newContractData.id = doc.id;

      if (doc.data().landlord) {
        const user = await doc.data().landlord.get();
        newContractData.landlord = user.data();
        contractsData.push(newContractData);
      } else {
        console.error('getContracts : Error : No author info');
      }
    }
    dispatch(slice.actions.getContracts(contractsData));
  } catch (err) {
    console.error(err);
  }
}

export const getContractTemplates = () => async (dispatch) => {
  try {
    const contractTemplatesData = [];
    const querySnapshot = await db.collection('contractTemplates').get();
    for (const doc of querySnapshot.docs) {
      const newContractTemplateData = doc.data();
      newContractTemplateData.id = doc.id;

      if (doc.data().author) {
        const user = await doc.data().author.get();
        newContractTemplateData.author = user.data();
        contractTemplatesData.push(newContractTemplateData);
      } else {
        console.error('getContracts : Error : No author info');
      }
    }
    dispatch(slice.actions.getContracts(contractTemplatesData));
  } catch (err) {
    console.error(err);
  }
}

export const getContractByUnit = (unitId) => async (dispatch) => {
  try {
    const unitDocRef = db.collection('units').doc(unitId);

    const querySnapshot = await db.collection('contracts')
      .where('unit', '==', unitDocRef)
      .get();

    if (querySnapshot.docs.length < 1) {
      return null;
    }

    const contractData = {
      contract: null,
      landlord: null,
      landlordSigned: querySnapshot.docs[0].data().landlordSigned || null,
      renter: null,
      renterSigned: querySnapshot.docs[0].data().renterSigned || null,
      unit: null,
    };

    const contractTemplateData = (await db.doc(querySnapshot.docs[0].data().contract).get()).data();
    contractData.contract = contractTemplateData;

    const landlordData = (await db.doc(querySnapshot.docs[0].data().landlord).get()).data();
    contractData.landlord = landlordData;

    const renterData = (await db.doc(querySnapshot.docs[0].data().renter).get()).data();
    contractData.renter = renterData;

    const unitData = (await db.doc(querySnapshot.docs[0].data().unit).get()).data();
    contractData.unit = unitData;

    dispatch(slice.actions.getContractByUnit(contractData));
  } catch (e) {
    console.error(e);
  }
}

export const getContractsSentByUser = (ownerId) => async (dispatch) => {
  try {
    const ownerDocRef = db.collection('users').doc(ownerId);

    const contractsData = [];

    const querySnapshot = await db.collection('contracts')
      .where('landlord', '==', ownerDocRef)
      .get();

    for (const doc of querySnapshot.docs) {
      const newContractData = {
        contract: doc.data().contract,
        landlord: null,
        landlordSigned: doc.data().landlordSigned || null,
        renter: doc.data().renter,
        renterSigned: doc.data().renterSigned || null,
        unit: doc.data().unit || null,
      }

      if (doc.data().landlord) {
        const landlord = await doc.data().owner.get();
        newContractData.landlord = landlord.data();
      } else {
        console.error('getContracts : Error : No owner info');
      }

      contractsData.push(newContractData);
    };

    dispatch(slice.actions.getContractsSentByUser(contractsData));
  } catch (err) {
    console.error(err);
  }
};

export const getContractsReceivedByUser = (userId) => async (dispatch) => {
  try {
    const userDocRef = db.collection('users').doc(userId);

    const contractsData = [];

    const querySnapshot = await db.collection('contracts')
      .where('renter', '==', userDocRef)
      .get();

    for (const doc of querySnapshot.docs) {
      const newContractData = {
        contract: doc.data().contract,
        landlord: doc.data().landlord,
        landlordSigned: doc.data().landlordSigned || null,
        renter: null,
        renterSigned: doc.data().renterSigned || null,
        unit: doc.data().unit || null,
      }

      if (doc.data().renter) {
        const renter = await doc.data().renter.get();
        newContractData.renter = renter.data();
      } else {
        console.error('getContracts : Error : No owner info');
      }

      contractsData.push(newContractData);
    }

    dispatch(slice.actions.getContractsReceivedByUser(contractsData));
  } catch (err) {
    console.error(err);
  }
};

export const sendNewContractToRenter = (data) => async (dispatch) => {

}

export const getContractTemplatesByUser = (userId) => async (dispatch) => {
  try {
    const userDocRef = db.collection('users').doc(userId);

    const contractsData = [];

    const querySnapshot = await db.collection('contractTemplates')
      .where('author', '==', userDocRef)
      .get();

    for(const doc of querySnapshot.docs) {
      const newContractTemplateData = {
        author: null,
        name: doc.data().name,
        text: doc.data().text,
        noticePeriod: doc.data().noticePeriod,
        deposit: doc.data().deposit,
        powerIncluded: doc.data().powerIncluded,
        internetIncluded: doc.data().internetIncluded,
        waterCharges: doc.data().waterCharges,
      }

      newContractTemplateData.id = doc.id;

      if (doc.data().author) {
        const author = await doc.data().author.get();
        newContractTemplateData.author = author.data();
      } else {
        console.error('getContractTemplates : Error : No owner info');
      }
      contractsData.push(newContractTemplateData);
    }
    dispatch(slice.actions.getContractTemplatesByUser(contractsData));
  } catch (err) {
    console.error(err);
  }
}

export const getTemplateById = templateId => async (dispatch) => {
  try {
    const doc = await db.collection('contractTemplates').doc(templateId).get();

    /*
    let renter = null;

    const renterDocRef = doc.data()['renter'];
    if (renterDocRef) {
      const renterDoc = await renterDocRef.get();
      renter = {
        id: renterDocRef.id,
        ...renterDoc.data(),
      }
    }
     */

    const templateDataById = {
      author: null,
      name: doc.data().name,
      text: doc.data().text,
      noticePeriod: doc.data().noticePeriod,
      deposit: doc.data().deposit,
      powerIncluded: doc.data().powerIncluded,
      internetIncluded: doc.data().internetIncluded,
      waterCharges: doc.data().waterCharges,
    };

    console.log(templateDataById);

    if (doc.data().author) {
      const author = await doc.data().author.get();
      templateDataById.author = author.data();
    } else {
      console.error('getContractTemplates : Error : No owner info');
    }

    console.log(templateDataById);

    dispatch(slice.actions.getTemplateById(templateDataById));
  } catch (err) {
    console.error(err);
  }
}

export const updateTemplate = () => async (dispatch) => {

}

export const getStandardContractTemplates = () => async (dispatch) => {
  try {

  } catch (err) {
    console.error(err);
  }
}

export default slice;
