import { createSlice } from '@reduxjs/toolkit';
import { db} from 'src/utils/firebase';
import moment from 'moment';

const initialState = {
  invoicesByOwnerId: [],     // landlord : true, current user's invoices without owner info
  invoicesByRenterId: [],    // landlord : false, current user's invoices info with owner info
  invoiceById: null,         // invoice got by it's id for invoice edit view or units view, without owner info
};

const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    getInvoiceById(state, action) {
      state.invoiceById = action.payload;
    },
    getInvoicesByOwnerId(state, action) {
      state.invoicesByOwnerId = action.payload;
    },
    getInvoicesByRenterId(state, action) {
      state.invoicesByRenterId = action.payload;
    },
    addInvoice(state, action) {
      state.invoicesByOwnerId.push(action.payload);
    },
    addInvoicesApartment(state, action) {
      state.invoicesByOwnerId = [...state.invoicesByOwnerId, ...action.payload];
    },
    deleteInvoice(state, action) {

    },
    updateInvoice(state, action) {
      state.invoiceById = {
        ...state.invoiceById,
        ...action.payload,
      };
    }, 
  }
});

export const reducer = slice.reducer;

export const getInvoicesByOwnerId = (ownerId) => async (dispatch) => {
  try {

    const ownerDocRef = db.collection('users').doc(ownerId);
    const ownerDoc = await ownerDocRef.get();
    const invoicesData = [];
    const querySnapshot = await db.collection('invoices')
    .where('owner', '==', ownerDocRef)
    .get();
    
    const owner = {
      id: ownerDoc.id,
      name: ownerDoc.data().name,
      email: ownerDoc.data().email,
      phone: ownerDoc.data().phone,
    };

    for(const doc of querySnapshot.docs) {
      const unitDocRef = doc.data()['owning-unit'];
      const unitDoc = await unitDocRef.get();
      const unit = {
        id: unitDoc.id,
        UID: unitDoc.data().UID,
        'monthly-price': unitDoc.data()['monthlyPrice'],
        'internet-extra': unitDoc.data()['internetExtra'],
        'electric-extra': unitDoc.data()['electricExtra'],
      }

      const renterDocRef = doc.data().renter;
      const renterDoc = await renterDocRef.get();
      const renter = {
        id: renterDoc.id,
        name: renterDoc.data().name,
        email: renterDoc.data().email,
        phone: renterDoc.data().phone,
      }

      const newInvoiceData = {
        id: doc.id,
        amount: doc.data().amount,
        ian: doc.data().ian,
        due: moment(doc.data().due.toDate().toString()).format("yyyy-MM-DD HH:mm:ss"),
        paid: doc.data().paid != null ? moment(doc.data().paid.toDate().toString()).format("yyyy-MM-DD HH:mm:ss") : null,
        'verified-paid': doc.data()['verified-paid'],
        unit: unit,
        owner: owner,
        renter: renter,
      };
      invoicesData.push(newInvoiceData);
    }

    dispatch(slice.actions.getInvoicesByOwnerId(invoicesData));
  } catch (err) {
    console.error(err); 
  }
};

export const getInvoicesByRenterId = (renterId) => async (dispatch) => {
  try {
    
    const renterDocRef = db.collection('users').doc(renterId);
    const renterDoc = await renterDocRef.get();
    const invoicesData = [];
    const querySnapshot = await db.collection('invoices')
    .where('renter', '==', renterDocRef)
    .get();
    
    const renter = {
      id: renterDoc.id,
      name: renterDoc.data().name,
      email: renterDoc.data().email,
      phone: renterDoc.data().phone,
    };

    for(const doc of querySnapshot.docs) {
      const unitDocRef = doc.data()['owning-unit'];
      const unitDoc = await unitDocRef.get();
      const unit = {
        id: unitDoc.id,
        UID: unitDoc.data().UID,
        'monthly-price': unitDoc.data()['monthlyPrice'],
        'internet-extra': unitDoc.data()['internetExtra'],
        'electric-extra': unitDoc.data()['electricExtra'],
      }

      const ownerDocRef = doc.data().owner;
      const ownerDoc = await ownerDocRef.get();
      const owner = {
        id: ownerDoc.id,
        name: ownerDoc.data().name,
        email: ownerDoc.data().email,
        phone: ownerDoc.data().phone,
      }

      const newInvoiceData = {
        id: doc.id,
        amount: doc.data().amount,
        ian: doc.data().ian,
        due: moment(doc.data().due.toDate().toString()).format("yyyy-MM-DD HH:mm:ss"),
        paid: doc.data().paid != null ? moment(doc.data().paid.toDate().toString()).format("yyyy-MM-DD HH:mm:ss") : null,
        'verified-paid': doc.data()['verified-paid'],
        unit: unit,
        owner: owner,
        renter: renter,
      };
      invoicesData.push(newInvoiceData);
    }
    
    dispatch(slice.actions.getInvoicesByRenterId(invoicesData));
  } catch (err) {
    console.error(err); 
  }
};

export const getInvoiceById = (invoiceId) => async (dispatch) => {
  try {
    const doc = await db.collection("invoices").doc(invoiceId).get();
    
    const ownerDocRef = doc.data().owner;
    const ownerDoc = await ownerDocRef.get();
    const owner = {
      id: ownerDoc.id,
      name: ownerDoc.data().name,
      email: ownerDoc.data().email,
      phone: ownerDoc.data().phone,
    };
    
    const unitDocRef = doc.data()['owning-unit'];
    const unitDoc = await unitDocRef.get();
    const unit = {
      id: unitDoc.id,
      UID: unitDoc.data().UID,
      'monthly-price': unitDoc.data()['monthlyPrice'],
      'internet-extra': unitDoc.data()['internetExtra'],
      'electric-extra': unitDoc.data()['electricExtra'],
    };

    const renterDocRef = doc.data().renter;
    const renterDoc = await renterDocRef.get();
    const renter = {
      id: renterDoc.id,
      name: renterDoc.data().name,
      email: renterDoc.data().email,
      phone: renterDoc.data().phone,
    };
    
    const invoiceDataById = {
      id: doc.id,
      amount: doc.data().amount,
      ian: doc.data().ian,
      due: moment(doc.data().due.toDate().toString()).format("yyyy-MM-DD HH:mm:ss"),
      paid: doc.data().paid != null ? moment(doc.data().paid.toDate().toString()).format("yyyy-MM-DD HH:mm:ss") : null,
      'verified-paid': doc.data()['verified-paid'],
      unit: unit,
      owner: owner,
      renter: renter,
    };
   
    dispatch(slice.actions.getInvoiceById(invoiceDataById));

  } catch (err) {
    console.error(err); 
  }
}

export const addInvoice = (owner, newInvoice) => async (dispatch) => {
  try{
    const ownerDocRef = db.doc(`users/${owner.id}`);
    
    const unitDocRef = db.collection('units').doc(newInvoice.unitId);
    const unitDoc = await unitDocRef.get();

    const unit = {
      id: unitDoc.id,
      UID: unitDoc.data().UID,
      'monthly-price': unitDoc.data()['monthlyPrice'],
      'internet-extra': unitDoc.data()['internetExtra'],
      'electric-extra': unitDoc.data()['electricExtra'],
    };
    let renter = {};
    let renterDoc = null;
    const renterDocRef = unitDoc.data().renter;
    if (renterDocRef) {
      renterDoc = await renterDocRef.get();
      renter = {
        id: renterDoc.id,
        name: renterDoc.data().name,
        email: renterDoc.data().email,
        phone: renterDoc.data().phone,
      };

    } else {
      return {
        success: false,
        message: 'Sorry, invioce can not be created because this unit is not assigned.',
      }
    }

    let newInvoiceDocData = {
      amount: newInvoice.amount,
      due: newInvoice.due.toDate(),
      ian: newInvoice.ian,
      owner: ownerDocRef,
      renter: renterDocRef,
      paid: null,
      'verified-paid': false,
      'owning-unit': unitDocRef,
    };

    const newInvoiceDocRef = await db.collection("invoices").doc();
    await newInvoiceDocRef.set(newInvoiceDocData);

    newInvoiceDocData = {
      id: newInvoiceDocRef.id,
      amount: newInvoice.amount,
      due: moment(newInvoice.due.toString()).format("yyyy-MM-DD HH:mm:ss"),
      ian: newInvoice.ian,
      paid: null,
      'verified-paid': false,
      owner: owner,
      renter: renter,
      unit: unit,
    }

    const emailDocRef = db.collection('mail').doc();
    await emailDocRef.set({
      template: {
        name: "invoice-norwegian",
        data: {
          name: renter.name,
          total: newInvoice.amount,
          description: `Til Konto.nr: ${newInvoice.ian}`,
          due_date: moment(newInvoice.due.toString()).format("DD-MM-yyyy"),
          amount: newInvoice.amount,
          date: moment().format('Do MMM YYYY'),
          invoice_id: newInvoiceDocRef.id,
          action_url: `https://dinleilighet.app/app/management/invoices/${newInvoiceDocRef.id}`
        }
      },
      to: renter.email
    });

    await dispatch(slice.actions.addInvoice(newInvoiceDocData))

    return {
      success: true,
      message: 'New invoce created successfully.',
    }

  } catch(err){
    console.error(err); 
  }
};

export const addInvoicesApartment = (owner, newInvoices) => async (dispatch) => {
  try{
    let newInvoicesData = [];
    const invoiceBatch = db.batch();

    const ownerDocRef = db.doc(`users/${owner.id}`);

    for(const unitId in newInvoices) {
      
      const unitDocRef = db.collection('units').doc(unitId);
      const unitDoc = await unitDocRef.get();

      const unit = {
        id: unitDoc.id,
        UID: unitDoc.data().UID,
        'monthly-price': unitDoc.data()['monthlyPrice'],
        'internet-extra': unitDoc.data()['internetExtra'],
        'electric-extra': unitDoc.data()['electricExtra'],
      };
      let renter = {};
      let renterDoc = null;
      const renterDocRef = unitDoc.data().renter;
      if (renterDocRef) {
        renterDoc = await renterDocRef.get();
        renter = {
          id: renterDoc.id,
          name: renterDoc.data().name,
          email: renterDoc.data().email,
          phone: renterDoc.data().phone,
        };

      } else {
        console.error('Add invoices to apartment : No renter info');
      }

      let newInvoiceDocData = {
        amount: newInvoices[unitId].amount,
        due: newInvoices[unitId].due,
        ian: newInvoices[unitId].ian,
        owner: ownerDocRef,
        renter: renterDocRef,
        paid: null,
        'verified-paid': false,
        'owning-unit': unitDocRef,
      };

      const newInvoiceDocRef = await db.collection("invoices").doc();
      invoiceBatch.set(newInvoiceDocRef, newInvoiceDocData);

      const emailDocRef = db.collection('mail').doc();
      await emailDocRef.set({
        template: {
          name: "invoice-norwegian",
          data: {
            name: renter.name,
            total: newInvoices[unitId].amount,
            description: `Til Konto.nr: ${newInvoices[unitId].ian}`,
            due_date: moment(newInvoices[unitId].due.toString()).format("DD-MM-yyyy").toString(),
            amount: newInvoices[unitId].amount,
            date: moment().format('Do MMM YYYY').toString(),
            invoice_id: newInvoiceDocRef.id,
            action_url: `https://dinleilighet.app/app/management/invoices/${newInvoiceDocRef.id}`
          }
        },
        to: renter.email
      });

      newInvoiceDocData = {
        id: newInvoiceDocRef.id,
        amount: newInvoices[unitId].amount,
        ian: newInvoices[unitId].ian,
        due: moment(newInvoices[unitId].due.toString()).format("yyyy-MM-DD HH:mm:ss"),
        paid: null,
        'verified-paid': false,
        owner: owner,
        renter: renter,
        unit: unit,
      }
      newInvoicesData.push(newInvoiceDocData);
    }
    await invoiceBatch.commit();

    dispatch(slice.actions.addInvoicesApartment(newInvoicesData))

  } catch(err){
    console.error(err); 
  }
};

export const updateInvoice = (newInvoice) => async (dispatch) => {
  try{
    const invoiceDocRef = db.collection('invoices').doc(newInvoice.id);
    
    const { id, ...updateInfo } = newInvoice;
    
    await invoiceDocRef.update(updateInfo);

    if (newInvoice.due) {
      newInvoice.due = moment(newInvoice.due.toString()).format("yyyy-MM-DD HH:mm:ss");
    }

    if (newInvoice.paid) {
      newInvoice.paid = moment(newInvoice.paid.toString()).format("yyyy-MM-DD HH:mm:ss");
    }

    dispatch(slice.actions.updateInvoice(newInvoice));
    
  } catch(err) {
    console.error(err);
  }
}


export default slice;
