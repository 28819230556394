import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-quill/dist/quill.snow.css';
import 'prismjs/prism';
import 'prismjs/components/prism-bash';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-jsx';
import 'nprogress/nprogress.css';
import 'src/__mocks__';
import 'src/assets/css/prism.css';
import 'src/mixins/chartjs';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { enableES5 } from 'immer';
import * as serviceWorker from 'src/serviceWorker';
import store from 'src/store';
import { SettingsProvider } from 'src/contexts/SettingsContext';
import App from 'src/App';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from '@stripe/react-stripe-js';
import {FirebaseAppProvider} from "reactfire";
import './utils/i18n';

enableES5();

export const firebaseConfig = {
  apiKey: "AIzaSyAwWQhN9Zr8y6u35YtuvbMI5v0RBUtVIf0",
  authDomain: "dinleilighet-54316.firebaseapp.com",
  databaseURL: "https://dinleilighet-54316.firebaseio.com",
  projectId: "dinleilighet-54316",
  storageBucket: "dinleilighet-54316.appspot.com",
  messagingSenderId: "913268625102",
  appId: "1:913268625102:web:7a1740721b1417087b0a9f",
  measurementId: "G-6WY59ECZWB"
};

export const stripePromise = loadStripe(
  'pk_live_51GAiIREEdC43txKhTZzW7qnWk6NGOJ3xRMZoMereqKxMH2W8D8E8ZARtXUuot0OGylx6cRyY0zm3KqhvWbAzqz9s00KbQsMN98'
);

ReactDOM.render(
  <Provider store={store}>
    <SettingsProvider>
      <FirebaseAppProvider firebaseConfig={firebaseConfig}>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
      </FirebaseAppProvider>
    </SettingsProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.register();
