import { createSlice } from '@reduxjs/toolkit';
import { db } from 'src/utils/firebase';

const initialState = {
  users: [],
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUsers(state, action) {
      state.users = action.payload;;
    },
    getUserByEmail(state, action) {
      // did nothing at the moment...
    },
    authStateChange(state, action) {

    },
    updateUserInfo(state, action) {

    },
  }
});

export const reducer = slice.reducer;


// export const authStateChange = () => async (dispatch) => {
//   try{
    

//     dispatch(slice.actions.authStateChange(newAuthState));
//   } catch (err) {
//     console.error(err);
//   }
// };

// export const updateUserInfo = () => async (dispatch) => {
//   try{
    

//     dispatch(slice.actions.updateUserState(newUserInfo));

//   } catch (err) {
//     console.error(err);
//   }
// };


export const getUsers = () => async (dispatch) => {
  try {
    const querySnapshot = await db.collection('users').get();
    const usersData = [];

    for(const doc of querySnapshot.docs) {
      let newUserData = doc.data();
      newUserData.id = doc.id;
      usersData.push(newUserData);
    }
    
    dispatch(slice.actions.getUsers(usersData));

  } catch (err) {
    console.error(err); 
  }
};

export const getUserByEmail = (email) => async (dispatch) => {
  try {
    const querySnapshot = await db.collection('users').where('email', '==', email).get();
    // Here, I assume that there exist only one user with the email in firestore...
    const doc = querySnapshot.docs[0];
    const userData = doc.data();
    await dispatch(slice.actions.getUserByEmail(userData));
    return userData;
  } catch (err) {
    console.log(err);
  }
}



export default slice;
