import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database';

export const firebaseConfig = {
  apiKey: "AIzaSyAwWQhN9Zr8y6u35YtuvbMI5v0RBUtVIf0",
  authDomain: "dinleilighet-54316.firebaseapp.com",
  databaseURL: "https://dinleilighet-54316.firebaseio.com",
  projectId: "dinleilighet-54316",
  storageBucket: "dinleilighet-54316.appspot.com",
  messagingSenderId: "913268625102",
  appId: "1:913268625102:web:7a1740721b1417087b0a9f",
  measurementId: "G-6WY59ECZWB"
};

firebase.initializeApp(firebaseConfig)

export default firebase;

export const db = firebase.firestore();
export const rtdb = firebase.database();
export const storage = firebase.storage();
export const auth = firebase.auth();
