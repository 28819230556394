import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import useAuth from '../hooks/useAuth';

import { parse } from 'query-string';

const GuestGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();

  const { location: { search } } = children.props.children.props;
  const parsed = parse(search);
  
  if (isAuthenticated) {
    if (parsed.backurl) {
      return <Redirect to={parsed.backurl} />;
    }
    return <Redirect to="/app/account" />;
  }

  return (
    <>
      {children}
    </>
  );
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
