import { createSlice } from '@reduxjs/toolkit';
import { db} from 'src/utils/firebase';
import moment from 'moment';

const initialState = {
  services: [],              // all services with owner info
  servicesByOwnerId: [],     // landlord : true, current user's services without owner info
  servicesByRenterId: [],    // landlord : false, current user's services info with owner info
  serviceById: null,         // service got by it's id for service edit view or units view, without owner info
};

const slice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    getServiceById(state, action) {
      state.serviceById = action.payload;
    },
    getServicesByOwnerId(state, action) {
      state.servicesByOwnerId = action.payload;
    },
    getServicesByRenterId(state, action) {
      state.servicesByRenterId = action.payload;
    },
    addService(state, action) {
      state.servicesByRenterId.push(action.payload);
    },
    deleteService(state, action) {

    },
    updateService(state, action) {
      state.serviceById = {
        ...state.serviceById,
        ...action.payload,
      };
    }, 
  }
});

export const reducer = slice.reducer;

export const getServicesByOwnerId = (ownerId) => async (dispatch) => {
  try {

    let servicesData = [];
    const ownerDocRef = db.collection('users').doc(ownerId);
    const serviceSnapshot = await db.collection('services')
    .where('owner', '==', ownerDocRef)
    .get();

    for(const serviceDoc of serviceSnapshot.docs) {
      
      const senderDocRef = serviceDoc.data().sender;
      
      let sender = {};
      if (senderDocRef) {
        const senderDoc = await senderDocRef.get();
        sender = {
          'id': senderDocRef.id,
          'name': senderDoc.data().name,
          'email': senderDoc.data().email,
          'phone': senderDoc.data().phone,
        }
      } else {
        console.error('No sender info');
      }

      const unitDocRef = serviceDoc.data()['owning-unit'];
      
      let unit = {};
      if (unitDocRef) {
        const unitDoc = await unitDocRef.get();
        unit = {
          'id': unitDocRef.id,
          'UID': unitDoc.data().UID,
        }
      } else {
        console.error('No owning unit info');
      }
      const newServiceData = {
        'id': serviceDoc.id,
        'date': moment(serviceDoc.data().date.toDate().toString()).format("yyyy-MM-DD HH:mm:ss"),
        'title': serviceDoc.data().title,
        'description': serviceDoc.data().description,
        'status': serviceDoc.data().status,
        'sender': sender,
        'unit': unit,
      };

      servicesData.push(newServiceData);
    }
    dispatch(slice.actions.getServicesByOwnerId(servicesData));
  } catch (err) {
    console.error(err); 
  }
};

export const getServicesByRenterId = (renterId) => async (dispatch) => {
  try {
    let servicesData = [];
    const renterDocRef = db.collection('users').doc(renterId);

    const serviceSnapshot = await db.collection('services')
    .where('sender', '==', renterDocRef)
    .get();

    for(const serviceDoc of serviceSnapshot.docs) {
      
      const senderDocRef = serviceDoc.data().sender;
      
      let sender = {};
      if (senderDocRef) {
        const senderDoc = await senderDocRef.get();
        sender = {
          'id': senderDocRef.id,
          'name': senderDoc.data().name,
          'email': senderDoc.data().email,
          'phone': senderDoc.data().phone,
        }
      } else {
        console.error('No sender info');
      }

      const unitDocRef = serviceDoc.data()['owning-unit'];
      
      let unit = {};
      if (unitDocRef) {
        const unitDoc = await unitDocRef.get();
        unit = {
          'id': unitDocRef.id,
          'UID': unitDoc.data().UID,
        }
      } else {
        console.error('No owning unit info');
      }
      const newServiceData = {
        'id': serviceDoc.id,
        'date': moment(serviceDoc.data().date.toDate().toString()).format("yyyy-MM-DD HH:mm:ss"),
        'title': serviceDoc.data().title,
        'description': serviceDoc.data().description,
        'status': serviceDoc.data().status,
        'sender': sender,
        'unit': unit,
      };

      servicesData.push(newServiceData);
    }
    dispatch(slice.actions.getServicesByRenterId(servicesData));
  } catch (err) {
    console.error(err); 
  }
};

export const getServiceById = (serviceId) => async (dispatch) => {
  try {
    const serviceDoc = await db.collection("services").doc(serviceId).get();

    const senderDocRef = serviceDoc.data().sender;
    
    let sender = {};
    if (senderDocRef) {
      const senderDoc = await senderDocRef.get();
      sender = {
        'id': senderDocRef.id,
        'name': senderDoc.data().name,
        'email': senderDoc.data().email,
        'phone': senderDoc.data().phone,
      }
    } else {
      console.error('No sender info');
    }

    const unitDocRef = serviceDoc.data()['owning-unit'];
    
    let unit = {};
    if (unitDocRef) {
      const unitDoc = await unitDocRef.get();
      unit = {
        'id': unitDocRef.id,
        'UID': unitDoc.data().UID,
      }
    } else {
      console.error('No owning unit info');
    }
      
    const newServiceData = {
      'id': serviceDoc.id,
      'date': moment(serviceDoc.data().date.toDate().toString()).format("yyyy-MM-DD HH:mm:ss"),
      'title': serviceDoc.data().title,
      'description': serviceDoc.data().description,
      'status': serviceDoc.data().status,
      'sender': sender,
      'unit': unit,
    }
    dispatch(slice.actions.getServiceById(newServiceData));

  } catch (err) {
    console.error(err); 
  }
}

export const addService = (renter, newService) => async (dispatch) => {
  try{
    const senderDocRef = db.doc(`users/${renter.id}`);
    const senderDoc = await senderDocRef.get()
    const unitDocRef = db.doc(`units/${newService.unitId}`)
    
    const unitDoc = await unitDocRef.get();
    const apartmentDocRef = unitDoc.data()['owning-apartment'];
    const apartmentDoc = await apartmentDocRef.get();
    const ownerDocRef = apartmentDoc.data().owner;

    let newServiceDocData = {
      title: newService.title,
      description: newService.description,
      sender: senderDocRef,
      date: new Date(newService.date.toString()),  
      owner: ownerDocRef,
      status: 'sent',
      'owning-unit': unitDocRef,
    }

    const newServiceDocRef = await db.collection("services").doc();
    await newServiceDocRef.set(newServiceDocData);

    newServiceDocData.id = newServiceDocRef.id;
    
    const sender = {
      'id': senderDocRef.id,
      'name': senderDoc.data().name,
      'email': senderDoc.data().email,
      'phone': senderDoc.data().phone,
    }

    const unit = {
      'id': unitDocRef.id,
      'UID': unitDoc.data().UID,
    }

    newServiceDocData = {
      id: newServiceDocData.id,
      title: newServiceDocData.title,
      description: newServiceDocData.description,
      status: 'sent',
      date: moment(newServiceDocData.date.toString()).format("yyyy-MM-DD HH:mm:ss"),
      'sender': sender,
      'owning-unit': unit,
    }

    dispatch(slice.actions.addService(newServiceDocData));

  } catch(err){
    console.error(err); 
  }
};

export const updateService = (newService) => async (dispatch) => {
  try{
    const serviceDocRef = db.collection('services').doc(newService.id);
    
    const { id, ...updateInfo } = newService;

    await serviceDocRef.update(updateInfo);

    if (newService.date) {
      newService.date = moment(newService.date.toString()).format("yyyy-MM-DD HH:mm:ss");
    }

    dispatch(slice.actions.updateService(newService));
    
  } catch(err) {
    console.error(err);
  }
}

export default slice;
