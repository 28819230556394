import { createSlice } from '@reduxjs/toolkit';
import { db } from 'src/utils/firebase';

import TokenGenerator from 'uuid-token-generator';

const zeroPad = (num, places) => String(num).padStart(places, '0');

const initialState = {
  unitsByIds: [],             // units selected by owner/renter and apartment ids
  unitsByRAIds: [],             // units selected by owner/renter and apartment ids
  unitsOccupiedByApartmentId: [], // occupied units by apartment id
  unitById: null,
  unitsByRenterId: [],
  unitsByOwnerId: [],
};

const slice = createSlice({
  name: 'unit',
  initialState,
  reducers: {
    getUnitsByApartmentId(state, action) {
      state.unitsByIds = action.payload;
    },
    getUnitsByRenterAndApartmentIds(state, action) {
      state.unitsByRAIds = action.payload;
    },
    getUnitsOccupiedByApartmentId(state, action) {
      state.unitsOccupiedByApartmentId = action.payload;
    },
    getUnitsByRenterId(state, action) {
      state.unitsByRenterId = action.payload;
    },
    getUnitsByOwnerId(state, action) {
      state.unitsByOwnerId = action.payload;
    },
    createUnitsMassively(state, action) {
      state.unitsByIds = action.payload;
    },
    createSingleUnit(state, action) {
      state.unitsByIds = action.payload;
    },
    getUnitById(state, action) {
      state.unitById = action.payload;
    },
    updateUnit(state, action) {
      state.unitById = {
        ...state.unitById,
        ...action.payload,
      };
    },
    updateUnitImages(state, action) {
      state.unitById = {
        ...state.unitById,
        ...action.payload,
      }
    }
  }
});

export const reducer = slice.reducer;

export const getUnitsByRenterAndApartmentIds = (params) => async (dispatch) => {
  try {
    const apartmentDocRef = db.collection('apartments').doc(params.apartmentId);
    const renterDocRef = db.collection('users').doc(params.renterId);

    const unitsData = [];
    const querySnapshot = await db.collection('units')
    .where('owningApartment', '==', apartmentDocRef)
    .where('renter', '==', renterDocRef)
    .get();

    for(const doc of querySnapshot.docs){
      let token = {};

      const unitDocRef = db.collection('units').doc(doc.id);

      const tokensSnapshot = await db.collection('tokens')
      .where('unit', '==', unitDocRef)
      .get();

      if (tokensSnapshot.docs.length == 1) {
        const tokenDoc = tokensSnapshot.docs[0];
        token = {
          'id': tokenDoc.id,
          'token' : tokenDoc.data().token
        }
      } else {
        console.error('token internal error');
      }

      const newUnitData = {
        'id': doc.id,
        'UID': doc.data()['UID'],
        'monthlyPrice': doc.data()['monthlyPrice'],
        'electricExtra': doc.data()['electricExtra'],
        'internetExtra': doc.data()['internetExtra'],
        'floor': doc.data()['floor'],
        'token': token,
        'images': doc.data().images,
      };
      unitsData.push(newUnitData);
    }
    dispatch(slice.actions.getUnitsByRenterAndApartmentIds(unitsData));
  } catch (err) {
    console.error(err);
  }
};

export const getUnitsByRenterId = (renterId) => async (dispatch) => {
  try {

    const renterDocRef = db.collection('users').doc(renterId);

    let unitsData = [];
    const querySnapshot = await db.collection('units')
    .where('renter', '==', renterDocRef)
    .get();

    for(const doc of querySnapshot.docs){
      let token = {};

      const unitDocRef = db.collection('units').doc(doc.id);

      const tokensSnapshot = await db.collection('tokens')
      .where('unit', '==', unitDocRef)
      .get();

      if (tokensSnapshot.docs.length == 1) {
        const tokenDoc = tokensSnapshot.docs[0];
        token = {
          'id': tokenDoc.id,
          'token' : tokenDoc.data().token
        }
      } else {
        console.error('token internal error');
      }

      const newUnitData = {
        'id': doc.id,
        'UID': doc.data()['UID'],
        'monthlyPrice': doc.data()['monthlyPrice'],
        'electricExtra': doc.data()['electricExtra'],
        'internetExtra': doc.data()['internetExtra'],
        'floor': doc.data()['floor'],
        'token': token,
        'images': doc.data()['images'],
      };
      unitsData.push(newUnitData);
    }
    dispatch(slice.actions.getUnitsByRenterId(unitsData));
  } catch (err) {
    console.error(err);
  }
};

// get only occupied units...
export const getUnitsByOwnerId = (ownerId) => async (dispatch) => {
  try {

    let unitsData = [];
    const ownerDocRef = db.collection('users').doc(ownerId);

    const querySnapshot = await db.collection('units')
    .where('owner', '==', ownerDocRef)
    .get();

    for(const doc of querySnapshot.docs){

      const renterDocRef = doc.data().renter;

      if (!renterDocRef) {
        continue;
      }

      const renterDoc = await renterDocRef.get();
      const renter = {
        id: renterDoc.id,
        name: renterDoc.data().name,
      }

      let token = {};

      const unitDocRef = db.collection('units').doc(doc.id);

      const tokensSnapshot = await db.collection('tokens')
      .where('unit', '==', unitDocRef)
      .get();

      if (tokensSnapshot.docs.length == 1) {
        const tokenDoc = tokensSnapshot.docs[0];
        token = {
          'id': tokenDoc.id,
          'token' : tokenDoc.data().token
        }
      } else {
        console.error('token internal error');
      }

      const newUnitData = {
        'id': doc.id,
        'UID': doc.data()['UID'],
        'monthlyPrice': doc.data()['monthlyPrice'],
        'electricExtra': doc.data()['electricExtra'],
        'internetExtra': doc.data()['internetExtra'],
        'floor': doc.data()['floor'],
        'token': token,
        'renter': renter,
        'images': doc.data()['images'],
      };
      unitsData.push(newUnitData);
    }
    dispatch(slice.actions.getUnitsByOwnerId(unitsData));
  } catch (err) {
    console.error(err);
  }
};


export const getUnitsByApartmentId = (apartmentId) => async (dispatch) => {
  try {
    const apartmentDocRef = db.collection('apartments').doc(apartmentId);

    const unitsData = [];
    const querySnapshot = await db.collection('units')
    .where('owningApartment', '==', apartmentDocRef)
    .get();

    for(const doc of querySnapshot.docs){
      let renter = {};
      let token = {};

      if (doc.data()['renter'] == null) {
        renter = null;
      } else {
        const renterDocRef = doc.data()['renter'];
        const renterDoc = await renterDocRef.get();
        renter = {
          id: renterDocRef.id,
          ...renterDoc.data(),
        }
      }

      const unitDocRef = db.collection('units').doc(doc.id);

      const tokensSnapshot = await db.collection('tokens')
      .where('unit', '==', unitDocRef)
      .get();

      if (tokensSnapshot.docs.length == 1) {
        const tokenDoc = tokensSnapshot.docs[0];
        token = {
          'id': tokenDoc.id,
          'token' : tokenDoc.data().token
        }
      } else {
        console.error('token internal error');
      }

      const newUnitData = {
        'id': doc.id,
        'UID': doc.data()['UID'],
        'monthlyPrice': doc.data()['monthlyPrice'],
        'electricExtra': doc.data()['electricExtra'],
        'internetExtra': doc.data()['internetExtra'],
        'floor': doc.data()['floor'],
        'renter': renter,
        'token': token,
        'images': doc.data()['images'],
      };
      unitsData.push(newUnitData);
    }

    dispatch(slice.actions.getUnitsByApartmentId(unitsData));
  } catch (err) {
    console.error(err);
  }
};

export const getUnitsOccupiedByApartmentId = (apartmentId) => async (dispatch) => {
  try {
    const apartmentDocRef = db.collection('apartments').doc(apartmentId);

    const unitsData = [];
    const querySnapshot = await db.collection('units')
    .where('owningApartment', '==', apartmentDocRef)
    .get();

    for(const doc of querySnapshot.docs){
      if (doc.data()['renter'] == null) {
        continue;
        renter = null;
      } else {
        const renterDocRef = doc.data()['renter'];
        const renterDoc = await renterDocRef.get();
        renter = {
          id: renterDocRef.id,
          ...renterDoc.data(),
        }
      }

      let renter = {};
      let token = {};

      const unitDocRef = db.collection('units').doc(doc.id);

      const tokensSnapshot = await db.collection('tokens')
      .where('unit', '==', unitDocRef)
      .get();

      if (tokensSnapshot.docs.length == 1) {
        const tokenDoc = tokensSnapshot.docs[0];
        token = {
          'id': tokenDoc.id,
          'token' : tokenDoc.data().token
        }
      } else {
        console.error('token internal error');
      }

      const newUnitData = {
        'id': doc.id,
        'UID': doc.data()['UID'],
        'monthlyPrice': doc.data()['monthlyPrice'],
        'electricExtra': doc.data()['electricExtra'],
        'internetExtra': doc.data()['internetExtra'],
        'floor': doc.data()['floor'],
        'renter': renter,
        'token': token,
        'images': doc.data()['images'],
      };
      unitsData.push(newUnitData);
    }
    dispatch(slice.actions.getUnitsOccupiedByApartmentId(unitsData));
  } catch (err) {
    console.error(err);
  }
};


export const createSingleUnit = (params) => async (dispatch) => {
  try {
    // const renterDocRef = db.collection('users').doc(params.renterId);
    const apartmentDocRef = db.collection('apartments').doc(params.apartmentId);
    const ownerDocRef = db.collection('users').doc(params.ownerId);

    const unitBatch = db.batch();
    const tokenBatch = db.batch();

    const apartmentDoc = await apartmentDocRef.get();

    const newUnits = [];
    const newTokens = [];

    const tokenGenerator = new TokenGenerator(256, TokenGenerator.BASE62);

    const newUnitDocRef = db.collection('units').doc();

    let newUnitData = {
      'UID': `U${zeroPad(apartmentDoc.data().floors)}${zeroPad(1, 3)}${params.apartmentId}`,
      'monthlyPrice': params['monthly-price'],
      'electricExtra': params['electric-extra'],
      'internetExtra': params['internet-extra'],
      'floor': apartmentDoc.data().floors,
      'type': 'Single-Unit',
      'owner': ownerDocRef,
      'renter': null,
      'images': [],
    };
    unitBatch.set(newUnitDocRef, {
      ...newUnitData,
      'owningApartment': apartmentDocRef,
    });
    newUnitData.id = newUnitDocRef.id;

    const newTokenDocRef = db.collection('tokens').doc();
    let newTokenData = {
      'token':  tokenGenerator.generate()
    }
    newTokens.push(newTokenData);
    tokenBatch.set(newTokenDocRef, {
      ...newTokenData,
      'unit': newUnitDocRef,
    });
    newTokenData.id = newTokenDocRef.id;
    newUnitData.token = newTokenData;

    delete newUnitData.owner;

    newUnits.push(newUnitData);
    await unitBatch.commit();

    // update corresponding apartment document.
    await db.collection('apartments').doc(params.apartmentId).update({
      units: 1,
      configured: true,
      unit: newUnitDocRef,
    });

    // create tokens here.
    await tokenBatch.commit();

    await dispatch(slice.actions.createSingleUnit(newUnits));

  } catch (err) {
    console.error(err);
  }
};

export const createUnitsMassively = (params) => async (dispatch) => {
  try {
    // const renterDocRef = db.collection('users').doc(params.renterId);
    const apartmentDocRef = db.collection('apartments').doc(params.apartmentId);
    const ownerDocRef = db.collection('users').doc(params.ownerId);

    const unitBatch = db.batch();
    const tokenBatch = db.batch();

    const apartmentDoc = await apartmentDocRef.get();

    let aType = apartmentDoc.data().type;
    if (aType === 'Apartment-Complex') {
      aType = apartmentDoc.data().unitType;
    }

    const newUnits = [];
    const newTokens = [];

    const tokenGenerator = new TokenGenerator(256, TokenGenerator.BASE62);

    let totoalCnts = 0;

    for(let floor = 0; floor < params.floors; floor++) {
      for(let cnt = 0; cnt < params.upf[floor]; cnt++) {
        const newUnitDocRef = db.collection('units').doc();
        let newUnitData = {
          'UID': `U${zeroPad(floor+1)}${zeroPad(cnt+1, 3)}${params.apartmentId}`,
          'monthlyPrice': params['monthly-price'],
          'electricExtra': params['electric-extra'],
          'internetExtra': params['internet-extra'],
          'floor': floor + 1,
          'type': aType,
          'owner': ownerDocRef,
          'renter': null,
          'images': [],
        };
        unitBatch.set(newUnitDocRef, {
          ...newUnitData,
          'owningApartment': apartmentDocRef,
        });
        newUnitData.id = newUnitDocRef.id;

        const newTokenDocRef = db.collection('tokens').doc();
        let newTokenData = {
          'token':  tokenGenerator.generate()
        }
        newTokens.push(newTokenData);
        tokenBatch.set(newTokenDocRef, {
          ...newTokenData,
          'unit': newUnitDocRef,
        });
        newTokenData.id = newTokenDocRef.id;
        newUnitData.token = newTokenData;

        delete newUnitData.owner;

        newUnits.push(newUnitData);
      }
      totoalCnts += params.upf[floor];
    }
    await unitBatch.commit();

    // update corresponding apartment document.
    await db.collection('apartments').doc(params.apartmentId).update({
      units: totoalCnts,
      configured: true,
    });

    // create tokens here.
    await tokenBatch.commit();

    await dispatch(slice.actions.createUnitsMassively(newUnits));

  } catch (err) {
    console.error(err);
  }
};

export const getUnitById = (unitId) => async (dispatch) => {
  try{
    const doc = await db.collection('units').doc(unitId).get();

    let renter = null;
    let token = {};

    const renterDocRef = doc.data()['renter'];
    if (renterDocRef)
    {
      const renterDoc = await renterDocRef.get();
      renter = {
        id: renterDocRef.id,
        ...renterDoc.data(),
      }
    }

    const unitDocRef = db.collection('units').doc(doc.id);

    const tokensSnapshot = await db.collection('tokens')
    .where('unit', '==', unitDocRef)
    .get();

    if (tokensSnapshot.docs.length == 1) {
      const tokenDoc = tokensSnapshot.docs[0];
      token = {
        'id': tokenDoc.id,
        'token' : tokenDoc.data().token
      }
    } else if (tokensSnapshot.docs.length > 1) {
      console.log('More than one token.')
    } else {
      console.error('token internal error');
    }

    const unitDataById = {
      'id': doc.id,
      'UID': doc.data().UID,
      'monthlyPrice': doc.data()['monthlyPrice'],
      'electricExtra': doc.data()['electricExtra'],
      'internetExtra': doc.data()['internetExtra'],
      'floor': doc.data().floor,
      'renter': renter,
      'token': token,
      'images': doc.data()['images'],
    };

    dispatch(slice.actions.getUnitById(unitDataById));

  } catch (err) {
    console.error(err);
  }
};

export const assignUnit = (params) => async (dispatch) => {
  try{
    const renterDocRef = db.collection('users').doc(params.user.id);
    const querySnapshot = await db.collection('tokens').where('token', '==', params.unitToken).get();
    let result = {};
    if (querySnapshot.docs.length == 0) {
      result = {
        success: false,
        message: 'Invalid token',
      };
    } else if (querySnapshot.docs.length > 1) {
      result = {
        success: false,
        message: 'Internal Error',
      };
    } else {
      const doc = querySnapshot.docs[0];
      const unitDocRef = doc.data().unit;
      const unitDoc = await unitDocRef.get();
      const unitData = unitDoc.data();
      if (unitData.renter) {
        result = {
          success: false,
          message: 'Unit with the token already assigned.'
        }
      } else {
        await unitDocRef.update({renter: renterDocRef});
        result = {
          success: true,
          message: 'Unit assignment success.'
        }
      }
    }
    return result;
  } catch (err) {
    console.error(err);
  }
};

export const updateUnit = (newUnit) => async (dispatch) => {
  try{
    const unitDocRef = db.collection('units').doc(newUnit.id);

    const { id, ...updateInfo } = newUnit;

    await unitDocRef.update(updateInfo);

    dispatch(slice.actions.updateUnit(newUnit));

  } catch(err) {
    console.error(err);
  }
}

export const updateUnitImages = (unit, newImages) => async (dispatch) => {
  try {
    const unitDocRef = db.collection('units').doc(unit.id);
    const unitDoc = await unitDocRef.get();

    const oldImages = unitDoc.data().images;

    const newImageArray = [...oldImages, ...newImages];

    await unitDocRef.update({images: newImageArray});

    unit.images = newImageArray;

    dispatch(slice.actions.updateUnitImages(unit));
  } catch (e) {
    console.error(e);
  }
}


export default slice;
