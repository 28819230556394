import React, {
  useState,
  useRef
} from 'react';
import { capitalCase } from 'change-case';
import {
  Badge,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Popover,
  SvgIcon,
  Switch,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';
import {useTranslation} from "react-i18next";
import NO from "src/static/NO.png";
import GB from "src/static/GB.png";

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 320,
    padding: theme.spacing(2)
  }
}));

const Language = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const {i18n} = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  }

  return (
    <>
      {
        i18n.language === 'en'
          ?
          <IconButton
            color="inherit"
            onClick={() => {changeLanguage('no')}}
            ref={ref}
          >
            <img src={NO} alt="GB" />
          </IconButton>
          :
          <IconButton
            color="inherit"
            onClick={() => {changeLanguage('en')}}
            ref={ref}
          >
            <img src={GB} alt="GB" />
          </IconButton>
      }
    </>
  );
}

export default Language;
