import React from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Hero from './Hero';
import About from './About';
import Features from './Features';
import Testimonials from './Testimonials';
import CTA from './CTA';
import FAQS from './FAQS';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {

  }
}));

const HomeView = () => {
  const classes = useStyles();
  const {t} = useTranslation();

  return (
    <Page
      className={classes.root}
      title={t('home.title')}
    >
      <Hero />
      <About />
      <Features />
      <Testimonials />
      <CTA />
      <FAQS />
    </Page>
  );
};

export default HomeView;
