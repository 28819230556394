import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as apartmentReducer } from 'src/slices/apartment';
import { reducer as unitReducer } from 'src/slices/unit';
import { reducer as userReducer } from 'src/slices/user';
import { reducer as serviceReducer } from 'src/slices/service';
import { reducer as invoiceReducer } from 'src/slices/invoice';
import { reducer as contractReducer } from 'src/slices/contract';
import { reducer as listingReducer } from 'src/slices/listing';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  apartment: apartmentReducer,
  unit: unitReducer,
  user: userReducer,
  service: serviceReducer,
  invoice: invoiceReducer,
  contract: contractReducer,
  listing: listingReducer,
});

export default rootReducer;
