import { auth, db } from 'src/utils/firebase';
import axios from "axios";

const API = 'https://us-central1-dinleilighet-54316.cloudfunctions.net/api';

export async function fetchFromAPI(endpointURL, opts) {
  const { method, body } = { method: 'POST', body: null, ...opts }

  const user = auth.currentUser;
  const token = user && (await user.getIdToken());

  const res = await fetch(`${API}/${endpointURL}`, {
    method,
    ...(body && { body: JSON.stringify(body) }),
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
  });

  return res.json();
}

export async function axiosPostRequest(endpoint, body) {
  const user = auth.currentUser;
  const token = user && (await user.getIdToken());

  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const res = await axios.post(`${API}/${endpoint}`, body, { headers })
    .catch((e) => {
      console.error(e);
    })

  return res;
}

export async function uploadToAPI(endpointURL, _data) {
  const { data } = { _data }

  const user = auth.currentUser;
  const token = user && (await user.getIdToken());

  console.log(data);

  const res = await fetch(`${API}/${endpointURL}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': `multipart/form-data: boundary=${data.boundaries}`,
      'Authorization': `Bearer ${token}`,
    },
    body: data
  });

  return res.json();
}
