import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';
import objFromArray from 'src/utils/objFromArray';
import {db, rtdb} from "../utils/firebase";
import TokenGenerator from "uuid-token-generator";

const initialState = {
  activeThreadById: null,
  contacts: [],
  threads: [],
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    getContacts(state, action) {
      state.contacts = action.payload;
    },
    getThreads(state, action) {
      state.threads = action.payload;
    },
    getThread(state, action) {
      state.activeThreadById = action.payload;
    },
    addMessage(state, action) {
      state.activeThreadById = action.payload;
    },
    resetActiveThread(state) {
      state.activeThreadById = null;
    },
    addThread(state, action) {
      state.threads = {
        ...state.threads,
        ...action.payload,
      }
    }
  }
});

export const reducer = slice.reducer;

export const getContacts = (user) => async (dispatch) => {
  let users = [];

  const userDocRef = db.collection('users').doc(user.id);

  if (user.landlord) {
    const unitsSnapshot = await db.collection('units')
      .where('renter', '!=', null)
      .where('owner', '==', userDocRef)
      .get();

    if (unitsSnapshot.docs.length > 0 ) {
      for (const doc of unitsSnapshot.docs) {
        const renterDocRef = doc.data().renter;

        if (!renterDocRef) {
          continue;
        }

        const renterDoc = await renterDocRef.get();

        const newUser = {
          'id': renterDoc.id,
          'name': renterDoc.data().name,
          'image': renterDoc.data().imageLink,
        };

        if (!users.includes(newUser)) {
          users.push(newUser);
        };
      }
    } else {
      return;
    }
  } else {
    const unitsSnapshot = await db.collection('units')
      .where('renter', '==', userDocRef)
      .get();

    if (unitsSnapshot.docs.length > 0) {
      for (const doc of unitsSnapshot.docs) {
        const ownerDocRef = doc.data().owner;

        if (!ownerDocRef) {
          continue;
        }

        const ownerDoc = await ownerDocRef.get();

        const newUser = {
          'id': ownerDoc.id,
          'name': ownerDoc.data().name,
          'image': ownerDoc.data().imageLink,
        };

        if (!users.includes(newUser)) {
          users.push(newUser);
        };
      }
    }
  }

  dispatch(slice.actions.getContacts(users));
};

export const getThreads = (user) => async (dispatch) => {
  try {
    let threads = [];

    const querySnapshot1 = await db.collection("threads")
      .where('participants.uid1', '==', user.id)
      .get();

    if (querySnapshot1.docs.length > 0) {
      for (const doc of querySnapshot1.docs) {
        let participants = []

        const user1Doc = await db.collection('users').doc(doc.data().participants.uid1).get();
        const user2Doc = await db.collection('users').doc(doc.data().participants.uid2).get();

        const user1 = {
          id: user1Doc.id,
          name: user1Doc.data().name,
          imageLink: user1Doc.data().imageLink,
        }
        const user2 = {
          id: user2Doc.id,
          name: user2Doc.data().name,
          imageLink: user2Doc.data().imageLink,
        }

        participants.push(user1);
        participants.push(user2);

        let newThreadData = {
          id: doc.data().id,
          messages: doc.data().messages,
          participants: participants,
          type: doc.data().type,
          unreadCount: doc.data().unreadCount
        }

        threads.push(newThreadData);
      }
    }

    const querySnapshot2 = await db.collection("threads")
      .where('participants.uid2', '==', user.id)
      .get();

    if (querySnapshot2.docs.length > 0) {
      for (const doc of querySnapshot2.docs) {
        let participants = []

        const user1Doc = await db.collection('users').doc(doc.data().participants.uid1).get();
        const user2Doc = await db.collection('users').doc(doc.data().participants.uid2).get();

        const user1 = {
          id: user1Doc.id,
          name: user1Doc.data().name,
          imageLink: user1Doc.data().imageLink,
        }
        const user2 = {
          id: user2Doc.id,
          name: user2Doc.data().name,
          imageLink: user2Doc.data().imageLink,
        }

        participants.push(user1);
        participants.push(user2);

        let newThreadData = {
          id: doc.data().id,
          messages: doc.data().messages,
          participants: participants,
          type: doc.data().type,
          unreadCount: doc.data().unreadCount
        }

        threads.push(newThreadData);
      }
    }

    dispatch(slice.actions.getThreads(threads));
  } catch (err) {
    console.error(err);
  }
};

export const getThread = (threadKey) => async (dispatch) => {
  try {
    const doc = await db.collection("threads").doc(threadKey).get();

    let threadData = {
      id: doc.data().id,
      messages: doc.data().messages,
      participants: doc.data().participants,
      type: doc.data().type,
      unreadCount: doc.data().unreadCount
    };

    dispatch(slice.actions.getThread(threadData));
  } catch (err) {
    console.error(err);
  }
};

export const addMessage = (threadKey, data) => async (dispatch) => {
  try {
    let updatedThreadData = {};

    const threadDocRef = db.collection('threads').doc(threadKey);
    const threadDoc = await threadDocRef.get();

    const tokenGenerator = new TokenGenerator(128, TokenGenerator.BASE36);
    const messageId = tokenGenerator.generate();

    let newMessage = {
      id: messageId,
      ...data,
    };

    const messages = threadDoc.data().messages;
    messages.push(newMessage);

    await threadDocRef.update({messages: messages});

    updatedThreadData = {
      id: threadDoc.data().id,
      messages: messages,
      participants: threadDoc.data().participants,
      type: threadDoc.data().type,
      unreadCount: threadDoc.data().unreadCount + 1
    };

    dispatch(slice.actions.addMessage(updatedThreadData));
  } catch (e) {
    console.error(e);
  }
}

export const addThread = (threadData) => async (dispatch) => {
  try {
    let newThreadData = {};

    const ref = db.collection('threads');

    const tokenGenerator = new TokenGenerator(128, TokenGenerator.BASE36);

    const id = tokenGenerator.generate();
    const messageId = tokenGenerator.generate();

    const firstMessage = {
      id: messageId,
      ...threadData.message,
    }

    console.log(firstMessage);

    await ref.doc(id).set({id: id, messages: [firstMessage], participants: {uid1:threadData.sender, uid2:threadData.receiver}, type: 'ONE_TO_ONE', unreadCount: 0})
      .then((response) => {
        newThreadData = {
          id: id,
          messages: [{firstMessage}],
          participants: [threadData.sender, threadData.receiver],
          type: 'ONE_TO_ONE',
          unreadCount: 0
        }
      })
      .catch((err) => {
        console.error(err);
        return;
      })

    dispatch(slice.actions.addThread(newThreadData));
  } catch (err) {
    console.error(err);
  }
}

export default slice;
