import React, {
  createContext,
  useEffect,
  useReducer
} from 'react';
import SplashScreen from 'src/components/SplashScreen';
import firebase, { db, auth } from 'src/utils/firebase';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'USER_INFO_CHANGED' : {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        user
      }
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  dispatch: () => {},
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const signInWithEmailAndPassword = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return auth.signInWithPopup(provider);
  };

  const createUserWithEmailAndPassword = async (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
  };

  const logout = () => {
    return auth.signOut();
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // Here, I am creating user account if one does not exist with user id
        const userDocRef = db.collection('users').doc(user.uid);
        userDocRef.get()
        .then(userDoc => {
          if (!userDoc.exists) {
            const newUser = {
              name: user.displayName || user.email,
              email: user.email,
              landlord: false,
              isPublic: false,
              phone:'',
              city:'',
              state:'',
              country:'',
              tier: 'Renter',
              stripeCustomerId: '',
              ian: '',
              imageLink: '',
              imageId: '',
            };
            userDocRef.set(newUser)
            .then(() => {
              dispatch({
                type: 'AUTH_STATE_CHANGED',
                payload: {
                  isAuthenticated: true,
                  user: {
                    id: user.uid,
                    email: user.email,
                    name: user.displayName || user.email,
                    landlord: false,
                    isPublic: false,
                    phone:'',
                    city:'',
                    state:'',
                    country:'',
                    stripeCustomerId: '',
                    tier: 'Renter',
                    ian: '',
                    imageLink: '',
                    imageId: '',
                  }
                }
              });
            })
            .catch(err => console.error(err))
          } else {
            dispatch({
              type: 'AUTH_STATE_CHANGED',
              payload: {
                isAuthenticated: true,
                user: {
                  id: userDoc.id,
                  avatar: user.photoURL,
                  email: userDoc.data().email,
                  name: userDoc.data().name,
                  landlord: userDoc.data().landlord,
                  isPublic: userDoc.data().isPublic,
                  phone: userDoc.data().phone,
                  city: userDoc.data().city,
                  state: userDoc.data().state,
                  country: userDoc.data().country,
                  stripeCustomerId: userDoc.data().stripeId,
                  tier: userDoc.data().tier,
                  ian: userDoc.data().ian,
                  imageLink: userDoc.data().imageLink,
                  imageId: userDoc.data().imageId,
                }
              }
            });
          }
        })
        .catch(err => console.log("Error in Firebase Auth : ", err));
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    });

    return unsubscribe;
  }, [dispatch]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        logout,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
